import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild 
{
    constructor(private authService: AuthService, private _router:Router) 
    {
        
    }

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):boolean 
    {
        let roles = route.data["roles"] as Array<string>;
        let url:string = state.url;
        return this.checkLogin(url, roles);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean 
    {
        return this.canActivate(route, state);
    }

    /**
       This function will check if the user is logged-in
       also if user is logged in then it also checks if the user has
       the permission to access the route based on user role
     */
    checkLogin(url: string, roles: Array<string>):boolean
    {
        if( this.authService.isLoggedIn() ) {
            //user is logged in
            let role = this.authService.getRole();
            if( roles.indexOf(role) != -1 ) {
                return true;
            } else {
                this._router.navigate(['/unauthorized']);
                return false; 
            }
        } else {
            //user is not logged in or the jwt has expired
             //logout if any expired jwt is present
            this.authService.logout();
            // Store the attempted URL for redirecting
            this.authService.redirectURL = url;
            // Navigate to the login page with extras
            this._router.navigate(['/auth/login']);
            return false;
        }
    }

}