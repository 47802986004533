import {Injectable} from '@angular/core';
import {API, ROLES} from '../../../config/config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/retry';
import {Observable} from 'rxjs/Rx';
import {Router} from "@angular/router";
import {tokenNotExpired} from 'angular2-jwt';
import {JwtHelper} from 'angular2-jwt';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';

@Injectable()
export class AuthService 
{
    public loggedIn = false;
    public redirectURL = '';
    public jwtHelper: JwtHelper = new JwtHelper();
    results: string[];
    public loggedinUser:string = '';
    public avatar : string = '';
    public scroll_id : string = '';
    constructor(private _router: Router, private http: HttpClient) 
    {
        this.loggedIn = this.isLoggedIn();
    }

    public login(email, password, q="") 
    {
        return this.http
            .post(
                API.ENDPOINT + 'auth/login',
                JSON.stringify({"email": email, "password": password, "q":q}),
             ).map((response:any) => { 
                if (response.success) {
                    localStorage.setItem('access_token', response.data.access_token);
                    this.loggedIn = true;
                    this.loggedinUser = this.getJWTValue().name;
                } else {
                    localStorage.removeItem('access_token');
                    this.loggedIn = false;
                }
                return response;
            })
            .catch(this.handleError);
    }

    public signup(name, email, password) 
    {
         return this.http
            .post(
                API.ENDPOINT + 'auth/register',
                JSON.stringify({
                        "name": name,
                        "email": email,
                        "password": password,
                }),
            )
            .map((response:any) => {
               return response;
            })
            .catch(this.handleError);
    }

    public signupConfirm(id, auth_key) 
    {
       return this.http
            .post(
                API.ENDPOINT + '/user/confirm',
                JSON.stringify({
                    "SignupConfirmForm": {
                        "id": id,
                        "auth_key": auth_key,
                    }
                })
            )
            .map((response:any) => {
                return response;
            })
            .catch(this.handleError);
    }

    public passwordResetRequest(email) 
    {
        return this.http
            .post(
                API.ENDPOINT + 'auth/forgot-password',
                JSON.stringify({
                    "email": email
                }),
            )
            .map((response:any) => {
                if (response.success) {
                } else {
                }
                return response;
            })
            .catch(this.handleError);
    }


    public passwordResetTokenVerification(token) 
    {
       return this.http
            .post(
                API.ENDPOINT + '/user/password-reset-token-verification',
                JSON.stringify({
                    "PasswordResetTokenVerificationForm": {
                        "token": token,
                    }
                }),
            )
            .map((response:any) => {
                if (response.success) {
                } else {
                }
                return response;
            })
            .catch(this.handleError);
    }

    public passwordReset(token, password) 
    {
        return this.http
            .post(
                API.ENDPOINT + 'auth/reset-password',
                JSON.stringify({
                    "token":token,
                    "password":password,
                    "password_confirmation":password
                }),
            )
            .map((response:any) => {
                return response;
            })
            .catch(this.handleError);
    }
    public changePassword(current_password, new_password, new_password_confirmation) 
    {
        return this.http
            .post(
                API.ENDPOINT + 'auth/change-password',
                JSON.stringify({
                    "current_password":current_password,
                    "new_password":new_password,
                }),
            )
            .map((response:any) => {
                return response;
            })
            .catch(this.handleError);
    }
    
    

    public getMe() 
    {
        return this.http
            .get(
                API.ENDPOINT + 'user/me'
             ).map((response:any) => { 
                return response;
            })
            .catch(this.handleError);
    }

    public logout(): void 
    {
        localStorage.removeItem('access_token');
        this.loggedIn = false;
    }

    public getToken(): any 
    {
        return localStorage.getItem('access_token');
    }

    private checkToken(): any 
    {
        return !!localStorage.getItem('access_token');
    }

    public unauthorizedAccess(error: any): void 
    {
        this.logout();
        this._router.navigate(['/login']);
    }

    public isLoggedIn(): boolean 
    {  
        if(this.getToken() == undefined || !this.getToken()){
            return false;
        }
       return true; 
    }
    
    public getRole(): any
    {   
        if(this.isLoggedIn()) {
            let token = this.getToken();
            let decoded = this.jwtHelper.decodeToken(token);
            return decoded.role;
        } else {
            return null;
        }
    }

    public getJWTValue(): any
    {
        if(this.isLoggedIn()) {
            let token = this.getToken();
            return this.jwtHelper.decodeToken(token);
        } else {
            return null;
        }
    }

    private handleError(error: Response | any) 
    {

        let errorMessage: any = {};
        // Connection error
        if (error.status == 0) {
            errorMessage = {
                success: false,
                status: 0,
                data: "Sorry, there was a connection error occurred. Please try again.",
                message:'Network connection error'
            };
        }
        else {
            if(typeof error.error =='string'){
                error.error = JSON.parse(error.error);
            } 
            let str = error.error['message'] ? error.error['message'] : error.statusText;
            errorMessage = {message: str};
       }
        return Observable.throw(errorMessage);
    }

    public rediretToLogin() :void 
    {
        this.logout();
        this._router.navigate(['/auth/login']);
    }
   
}