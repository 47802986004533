﻿import {Injectable} from '@angular/core';
import {API} from '../../../config/config';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/retry';
import {Observable} from 'rxjs/Rx';
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse, HttpHandler, HttpEvent } from '@angular/common/http';

@Injectable()
export class FileDownloadService {
   
    constructor(private _router: Router, private http: HttpClient) {}

    download(url: string, formData:Object = {}): Observable<any> {
        return this.http
            .post(
                url,
                formData,
                {observe: 'response', responseType: 'blob'}
              ).map((response:HttpResponse<Blob>) => { console.log(response);
                return response;
            })
            .catch(this.handleError);

    }

  // handle error

   private handleError(error: Response | any) {

        let errorMessage: any = {};
        // Connection error
        if (error.status == 0) {
            errorMessage = {
                success: false,
                status: 0,
                data: "Sorry, there was a connection error occurred. Please try again.",
                message:'Network/Api connection error'
            };
        }
        else {
            if(typeof error.error =='string'){
                error.error = JSON.parse(error.error);
            } 
            let str = error.error['message'] ? error.error['message'] : error.statusText;
            errorMessage = {message: str};
        }
        return Observable.throw(errorMessage);
    }
 
}