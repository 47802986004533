import { Component } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { DOMEvents } from "./common/services/dom-events";
@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'	
})
export class AppComponent 
{
    constructor(private router: Router, private domEvents: DOMEvents) {}
    ngOnInit() 
    {
        this.router.events
          .subscribe((event) => {
            if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                this.domEvents.triggerOnDocument( "hideScreenLoader" );
            }
            if (event instanceof NavigationStart) {
                this.domEvents.triggerOnDocument( "showScreenLoader" );
            }
          });
    }
}
