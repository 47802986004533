// The file contents for the required configuration settings
// including api endpoint and user roles
export const API = {
    SERVER_URL: 'https://api.mybeautysquad.com',
    ENDPOINT:  'https://api.mybeautysquad.com/v2/'
};

export const SECURITYCODE = "Ye6x3BJa$XrRzW8D58yAy!#!";
export const S3_BUKCET_BASE_URL = 'https://djx02n1r596n5.cloudfront.net';
//user roles. rolename=>role value
export const ROLES = {
    ADMIN: 'admin',
    CUSTOMER: 'customer',
    STYLIST: 'stylist',
    MARKETING:'marketing'
};
//Default navigations based on roles rolename => navigation url
export const DEFAULT_NAVIGATION = {
    ADMIN: '/',
    CUSTOMER: '/',
    STYLIST: '/',
    MARKETING:'/news-feeds'
};
