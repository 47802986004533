import { Injectable,  Inject, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import {Router} from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor 
{
    headers: any = {
        'X-Requested-With':"XMLHttpRequest",
        'Content-Type'    : "application/json",
    };
    constructor(private inj: Injector, private _router:Router)
    {
           
    }
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> 
    {
        const auth = this.inj.get(AuthService);
        //SET AUTHORIZATION TOKEN IF USER IS LOGGED IN
        if( auth.isLoggedIn() ) {
            this.headers['Authorization'] = 'Bearer '+ auth.getToken();
        }
        //REMOVE CONTENT-TYPE HEADER IF WE'RE SENDING multipart/form-data
        if( request.body instanceof FormData ) {
            delete this.headers['Content-Type'];
        }
        else{
            this.headers['Content-Type'] = "application/json";
        }
        //set headers to the request
        request = request.clone({
                        setHeaders: this.headers
                    });
        return next.handle(request)
        .do(event => {
            if (event instanceof HttpResponse) {
               return event;
            }
        })
        .catch((err) => { console.log(err);
            if (err.status === 401) {
               localStorage.removeItem('access_token');
               this._router.navigate(['/auth/login']);
               return Observable.throw(err);
            }
            return Observable.throw(err);
        });
    }
}

