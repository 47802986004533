import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {P404Component, P401Component} from './common/pages/errors.component';
import {CommonModule} from '@angular/common';

export const routes: Routes = [
    {   
        path: '',
        children: [
            {
                path: '',
                loadChildren: './admin/admin.module#AdminModule' 
            },
            {
                path: 'unauthorized',
                component: P401Component 
            },
        ]
    },
    //otherwise page not found
    { path: '**', component: P404Component }
];

@NgModule({
    declarations: [
        P404Component,
        P401Component
    ],
    imports: [
        RouterModule.forRoot(routes, { enableTracing: true }), 
        CommonModule
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
