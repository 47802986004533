import { Component } from '@angular/core';

@Component({
    templateUrl: '404.component.html'
})
export class P404Component {

    constructor() { }

}

@Component({
    templateUrl: '401.component.html'
})
export class P401Component {

    constructor() { }

}
