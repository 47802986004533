import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
// Routing Module
import { AppRoutingModule } from './app.routing';
//services modules
import { AuthService } from './common/services/auth.service';
import { AuthGuard } from './common/services/auth.guard';
import { IsLoggedInGuard } from './common/services/isLoggedIn.guard';
import { FileUploadService } from './common/services/fileupload.service';
import { FileDownloadService } from './common/services/filedownload.service';

import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './common/services/token.interceptor';
import { DOMEvents } from "./common/services/dom-events";
//import {ROUTER_PROVIDERS} from '@angular/router';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { DataTablesModule } from 'angular-datatables';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
        HttpClientModule,
        DataTablesModule
    ],
    providers: [
        AuthService,
        AuthGuard,
        IsLoggedInGuard,
        FileUploadService,
        FileDownloadService,
        //ROUTER_PROVIDERS,
        DOMEvents,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        /*{
            provide: LocationStrategy, 
            useClass: HashLocationStrategy
        }*/
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
