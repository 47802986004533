import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild} from '@angular/router';
import {AuthService} from './auth.service';
import * as _ from 'lodash';
import {ROLES, DEFAULT_NAVIGATION} from '../../../config/config';

@Injectable()
export class IsLoggedInGuard implements CanActivate, CanActivateChild 
{
    constructor(private authService: AuthService, private _router:Router) 
    {
        
    }

    canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot):boolean 
    {   
        return this.checkLogin(state);
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean 
    {
        return this.canActivate(route, state);
    }

    /**
       This function will check if the user is logged-in
       also if user is logged in then redirect to corresponding 
       page, if not then return true to show login page
     */
    checkLogin(state):boolean
    {
        if( this.authService.isLoggedIn() ) {
            //check if it's a reset-password route
            if( state.url.indexOf('reset-password') !== -1 ) 
            {
                this.authService.logout();
                return true;
            }
            this._router.navigate(['/']); 
            return false;
        } else {
            //user is not logged in or the jwt has expired
            //logout if any expired jwt is present
            this.authService.logout();
            return true;
        }
    }
}